<template>
	<div class="work">
		<div class="work-c">
			<section
				class="l-work-subsection align-center"
				id="executive-recruitment"
				data-scroll-section
			>
				<div class="main-features">
					<h1
						class="title title-big title-general"
						data-scroll="fade-up"
						data-scroll-delay="1"
					>
						The Work: Executive Recruitment
					</h1>
					<ul class="main-list">
						<li class="main-item" data-scroll="fade-up" data-scroll-delay="1">
							<div class="c-card c-card-lines-white">
								<figure class="icon">
									<img
										alt="exchange"
										:src="require(`@/assets/work/icon-client-profile.svg`)"
									/>
								</figure>
								<h3 class="subtitle">Client Profile</h3>
								<div class="text-c">
									<p class="text">
										&bull; US $4 billion global financial services institution
									</p>
									<p class="text">
										&bull; US $2 trillion in assets under management
									</p>
									<p class="text">
										&bull; Top three player in each region of the world
									</p>
									<p class="text">&bull; A newly arrived CEO</p>
								</div>
							</div>
						</li>
						<li class="main-item" data-scroll="fade-up" data-scroll-delay="2">
							<div class="c-card c-card-lines-white">
								<figure class="icon">
									<img
										alt="exchange"
										:src="require(`@/assets/work/icon-client-situation.svg`)"
									/>
								</figure>
								<div class="text-c">
									<h3 class="subtitle">Client Situation</h3>
									<div class="text-c">
										<p class="text">
											The new CEO role began his tenure with a publicly
											articulated ambition to double the firm’s revenues within
											five years, requiring a dramatic increase in the firm’s
											performance in Asia.
										</p>
										<p class="text">
											Incumbent leadership in the region was excellent, but
											needed help to achieve the next level.
										</p>
										<p class="text">
											The core question: why had it been less successful in Asia
											than in other markets?
										</p>
									</div>
								</div>
							</div>
						</li>
						<li class="main-item" data-scroll="fade-up" data-scroll-delay="3">
							<div class="c-card c-card-lines-white">
								<figure class="icon">
									<img
										alt="exchange"
										:src="require(`@/assets/work/icon-diagnostic-finding.svg`)"
									/>
								</figure>
								<div class="text-c">
									<h3 class="subtitle">Diagnostic Finding</h3>
									<div class="text-c">
										<p class="text">
											Ascentador conducted its proprietary Diagnostic discovery
											process with a broad and diverse group of executives in
											the company.
										</p>
										<p class="text">
											Though our team observed an organization that was
											undeniably doing the right things, and “firing on all
											cylinders”, the leadership team was overly homogenous and
											uniform in its perspectives, attitudes, and backgrounds.
										</p>
										<p class="text">
											The Asia management team of the company was mainly
											comprised of long-tenured expatriate employees, with
											comparatively short ties to the region and its diverse
											cultures.
										</p>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>

				<div class="bespoke-solution align-center">
					<h1
						class="title title-small title-general"
						data-scroll="fade-up"
						data-scroll-delay="1"
					>
						<span>Ascentador</span>Bespoke Solution
					</h1>
					<h3
						class="highlight highlight-subsentence"
						data-scroll="fade-up"
						data-scroll-delay="2"
					>
						Our team approached the search process in a tailored and selective
						fashion with several key differentiating components.
					</h3>
					<ul class="main-list">
						<li class="main-item" data-scroll="fade-up" data-scroll-delay="1">
							<div class="c-card">
								<span class="number">1</span>
								<h3 class="subtitle">Design a Custom Search Protocol</h3>
								<div class="text-c">
									<p class="text">
										We designed and impemented a structured and controlled
										protocol for identifying and developing candidates who met a
										threefold requirement:
									</p>
									<ul class="text-list">
										<li class="text-item">
											<p class="text">
												Experienced and appropriately senior, in order to
												alleviate concerns about suitability for the role.
											</p>
										</li>
										<li class="text-item">
											<p class="text">
												An ethnic Asian, with operating experience in the West,
												which would enable him or her to capitalize on the
												untapped opportunities in the region, while still able
												to “speak the language” of associates at headquarters.
											</p>
										</li>
										<li class="text-item">
											<p class="text">
												A proven innovator and lateral thinker who had
												previously demonstrated the ability to see what other
												eyes had missed and to act decisively to capture such
												opportunities for the benefit of the organization.
											</p>
										</li>
									</ul>
								</div>
							</div>
						</li>
						<li class="main-item" data-scroll="fade-up" data-scroll-delay="2">
							<div class="c-card">
								<span class="number">2</span>
								<div class="text-c">
									<h3 class="subtitle">Create a Level Playing Field</h3>
									<div class="text-c">
										<p class="text">
											Given significant internal political concerns, our team
											involved a small group of internal candidates in a
											parallel process.
										</p>
										<p class="text">
											Although our client had not anticipated finding a viable
											internal candidate at the outset of this process, our
											assessment not only revealed previously unrecognized
											internal competencies, but ensured these employees found
											the process fair, inspiring and positive.
										</p>
									</div>
								</div>
							</div>
						</li>
						<li class="main-item" data-scroll="fade-up" data-scroll-delay="3">
							<div class="c-card">
								<span class="number">3</span>
								<div class="text-c">
									<h3 class="subtitle">Facilitate the On-Boarding Process</h3>
									<div class="text-c">
										<p class="text">
											Once the finalist candidate had been identified and
											accepted the role, the candidate went through an effective
											on-boarding and transition plan that was developed by
											Ascentador in collaboration with the client.
										</p>
										<p class="text">
											The goal was to ensure that the incoming executive was
											totally prepared to receive the baton before the incumbent
											took up his next assignment.
										</p>
										<p class="text">
											In very short order, the incoming executive had built
											effective relationships with headquarters and around the
											region, developing close and trusting working
											relationships with all key constituents and raising the
											Firm’s visibility in Asia.
										</p>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>

				<div class="watch-video align-center">
					<button
						data-scroll="fade-up"
						class="button"
						v-for="(image, imageIndex) in video"
						:key="imageIndex"
						@click="index = imageIndex"
					>
						How is our approach to executive recruitment different?
					</button>
				</div>
			</section>
		</div>
		<CoolLightBox
			:items="video"
			:index="index"
			@on-open="hideNavbar"
			@close="onClosePopUp"
		/>
	</div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
	name: "ExecutiveRecruitment",
	metaInfo() {
		return {
			title: "Ascentador | The Work",
			meta: [
				{
					name: "description",
					content:
						"The Diagnostic Process brings together a team of expert consultants and key client stakeholders prior to launching any project. Using a proprietary form of guided questioning, the goal is to gain insight, uncover a lack of alignment and reveal unspoken agendas before laying the groundwork for the development of lateral solutions.",
				},
			],
		};
	},
	components: {
		CoolLightBox,
	},
	data() {
		return {
			popupIsClose: false,
			video: [
				{
					title: "Ascentador Model explained in under 4 minutes",
					description: "Ascentador",
					thumb: "Watch the Explainer Video",
					src: "https://www.youtube.com/watch?v=yhfTZL58v9Q",
				},
			],
			index: null,
		};
	},
	methods: {
		hideNavbar() {
			this.header.classList.add("hidden");
		},
		onClosePopUp() {
			this.index = null;
			this.header.classList.remove("hidden");
		},
	},
	mounted() {
		this.$emit("view-mounted");
		this.header = document.querySelector(".header");
	},
};
</script>
